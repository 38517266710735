import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'precioEstablecido'
})
export class precioEstablecido implements PipeTransform {
transform(value:string):string {
    
    
    value=value||"0";
    value=value.trim();
    

    if(parseFloat(value)<=0)
        return "";


    if(parseFloat(value)>0){
      value="+ $"+parseFloat(value);
    }else{
      
      value="- $"+parseFloat(value);
    }
    
    return value;
    
  }
}