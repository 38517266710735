import { Component, OnInit, OnDestroy, AfterViewInit,EventEmitter, Inject, ChangeDetectorRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AppState } from "src/core/state.interface";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'carga-diseño-component',
    templateUrl: './carga-disenio.html',
    styleUrls: ['./carga-disenio.scss']
  })
  export class CargaDisenioDialog implements OnInit, OnDestroy, AfterViewInit {
   
    public data:any={};
    formDireccion:FormGroup;
    constructor(
        private changeDetectRef: ChangeDetectorRef,
        private appState$: Store<AppState>,
      public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public _data) {
        /* setTimeout(()=>{
          console.log("iniciandos...")
          this.changeDetectRef.detectChanges(); public dialogRef: MatDialogRef<DireccionDialog>,
        },300) */
        this.data=_data;
        
        
      }
  
  
    ngOnDestroy(): void {
     //if(this.eventE)this.eventE.unsubscribe();
    }

   

    
    ngAfterViewInit(): void {
    }
    ngOnInit(): void {
      
    }


   
  
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    
   
  
  }