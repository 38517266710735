/* Devroldan */

export class CheckoutProductModel {
    public product: any = [];

    constructor(productRequest: any) {
        console.log("viendo que ondax");
        console.log(productRequest);
        for (let i = 0; i < productRequest.length; i++) {
            const details: any = {};
            details.productId = productRequest[i].productId || '';
            details.quantity = productRequest[i].productCount || '';
            details.campos= JSON.stringify(productRequest[i].dataFormulario) || '';

            let tempPrice: any;
           // console.log(productRequest[i]._totalOptions +"+"+ (+productRequest[i].price))
            //devroldan comment
           //tempPrice = productRequest[i]._totalOptions + (+productRequest[i].price);
            tempPrice=productRequest[i].price;
            details.price = tempPrice || '';
            details.model = productRequest[i].metaTagTitle || '';
            details.name = productRequest[i].name || '';
            this.product.push(details);
        }
        //console.log(this.product);
    }
}

export class CheckoutModel {
    public productDetails: any;
    public shippingFirstName: any;
    public shippingLastName: any;
    public shippingCompany: any;
    public shippingAddress_1: any;
    public shippingAddress_2: any;
    public shippingCity: any;
    public shippingPostCode: any;
    public shippingCountry: any;
    public shippingZone: any;
    public shippingAddressFormat: any;
    public phoneNumber: any;
    public emailId: any;
    public cliente_id:any;
    public vendedor_id:any;
    public campos:any;
    public tipo_envio:any;
    public direcciones:any;
    public perfil_id:any;
    public promesa:any;

    constructor(checkoutRequest: any) {
        this.shippingFirstName = checkoutRequest.firstName || '';
        this.shippingLastName = checkoutRequest.lastName || '';
        this.shippingCompany = checkoutRequest.company || '';
        this.shippingAddress_1 = checkoutRequest.address || '';
        this.shippingAddress_2 = checkoutRequest.addressLine || '';
        this.shippingCity = checkoutRequest.city || '';
        this.shippingPostCode = checkoutRequest.zip || '';
        this.shippingCountry = checkoutRequest.country || '';
        this.shippingZone = checkoutRequest.state || '';
        this.phoneNumber = checkoutRequest.phone || '';
        this.shippingAddressFormat = '';
        this.emailId = checkoutRequest.email || '';
        this.cliente_id=checkoutRequest.cliente_id||'';
        this.vendedor_id=checkoutRequest.vendedor_id || '';
        this.campos=checkoutRequest.campos || '';
        this.productDetails = new CheckoutProductModel(checkoutRequest.productDetail);
        this.tipo_envio= checkoutRequest.tipo_envio || 3;
        this.direcciones= checkoutRequest.direcciones || "[]";
        this.perfil_id = checkoutRequest.perfil_id || '';
        this.promesa = checkoutRequest.promesa || undefined;
    }
}



