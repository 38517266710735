import { NgModule } from "@angular/core";
import { nombreUnidad } from "./nombreUnitdad";
import { pPrecios } from "./pPrecios";
import { precioEstablecido } from "./precioEstablecido";

@NgModule({
    imports: [
      // dep modules
    ],
    declarations: [ 
      pPrecios,
      precioEstablecido,
      nombreUnidad
    ],
    exports: [
        pPrecios,
        nombreUnidad,
        precioEstablecido
    ]
  })
  export class DevroldanPipesModule {}