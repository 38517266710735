import { Component, OnInit, OnDestroy, AfterViewInit,EventEmitter, Inject, ChangeDetectorRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AppState } from "src/core/state.interface";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'dialog-confirmacion-component',
    templateUrl: './confirmacion.dialog.html',
    styleUrls: ['./confirmacion.dialog.scss']
  })
  export class ConfirmacionDialog implements OnInit, OnDestroy, AfterViewInit {
   
    public data:any={};
    formDireccion:FormGroup;
    public titulo:string;

    public resumen:any[]=[];
    public general:any[]=[];

    modelMensajes:any[]=[
    {
      titulo:"Precaución",
      mensaje:"¿Toda la información que ingresaste es correcta?",
      btnOk:"Si, confirmo",
      btnCan:"No estoy seguro, regresar"

    },
    {
      titulo:"Precaución",
      mensaje:"¿Toda la información que ingresaste es correcta?",
      btnOk:"Si, confirmo",
      btnCan:"No estoy seguro, regresar"

    },
    {
      titulo:"Estás por añadir este producto a tu carrito",
      mensaje:"¿Toda la información que ingresaste es correcta?",
      btnOk:"Si, confirmo",
      btnCan:"No estoy seguro, regresar"

    }


    ];
    public indiceElejido=0;
    constructor(
        private changeDetectRef: ChangeDetectorRef,
        private appState$: Store<AppState>,
      public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public _data) {
        /* setTimeout(()=>{
          console.log("iniciandos...")
          this.changeDetectRef.detectChanges(); public dialogRef: MatDialogRef<DireccionDialog>,
        },300) */
        this.data=_data;
        console.log("DDDDD:",_data) 

        if(this.data.resumen){
          for(let x in this.data.resumen.campos){
            this.resumen.push(
              {
                label:x,
                valor:this.data.resumen.campos[x]
              }
            )
          }
          for(let x in this.data.resumen.general){
            this.general.push(
              {
                label:x,
                valor:this.data.resumen.general[x]
              }
            )
          }
        }

        this.indiceElejido=this.data.select;
        this.titulo=this.modelMensajes[this.indiceElejido].titulo;
        
      }
  
  
    ngOnDestroy(): void {
     //if(this.eventE)this.eventE.unsubscribe();
    }

   

    
    ngAfterViewInit(): void {
    }
    ngOnInit(): void {
      
    }


   
  
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    
   
  
  }