/* Devroldan */



export class ProductDetailResponseModel {
    public Category: Array<any>;
    public dateAvailable: string;
    public description: string;
    public location: string;
    public manufacturerId: string;
    public metaTagTitle: string;
    public minimumQuantity: string;
    public name: string;
    public price: string;
    public price2: string;
    public productId: string;
    public productImage: Array<any>;
    public productFieldDev: Array<any>;
    public quantity: string;
    public shipping: string;
    public sku: string;
    public sortOrder: string;
    public stockStatusId: string;
    public subtractStock: string;
    public upc: string;
    public wishListStatus: number;
    public descuentos:any;

    public unidad_medida:string;
    public cancelar_cantidad:string;
    public medida_largo:string;
    public medida_alto:string;   
    public medida_fija:string;
    public producto_medible:string;
    public nombre_unidad:string;

    public minima_cantidad:string;
    public cantidad_inicial:string;
    public incremento:string;
    public cantidad_prohibida:string;
    public dinamico:string="0";
    public tiene_disenio:any="0";
    public es_instalable:any="0";
    public ignorar_medida_metrica:any="0";


    constructor(listResponse: any) {
        this.description = listResponse.description || '';
        this.Category = listResponse.Category || [];
        this.location = listResponse.location || '';
        this.dateAvailable = listResponse.dateAvailable || '';
        this.manufacturerId = listResponse.manufacturerId || '';
        this.metaTagTitle = listResponse.metaTagTitle || '';
        this.minimumQuantity = listResponse.minimumQuantity || '';
        this.name = listResponse.name || '';
        this.price = listResponse.price || '';
        this.price2 = listResponse.price2 || '';
        this.productId = listResponse.productId || '';
        this.productImage = listResponse.productImage || [];
        this.productFieldDev = listResponse.productFieldDev || [];
        this.quantity = listResponse.quantity || '';
        this.shipping = listResponse.shipping || '';
        this.sku = listResponse.sku || '';
        this.sortOrder = listResponse.sortOrder || '';
        this.stockStatusId = listResponse.stockStatusId || '';
        this.subtractStock = listResponse.subtractStock || '';
        this.upc = listResponse.upc || '';
        this.wishListStatus = listResponse.wishListStatus || 0;
        this.descuentos = listResponse.descuentos || {};
        this.unidad_medida = listResponse.unidad_medida || '';
        this.cancelar_cantidad = listResponse.cancelar_cantidad || '';
        this.medida_largo = listResponse.medida_largo || '';
        this.medida_alto = listResponse.medida_alto || '';
        this.medida_fija = listResponse.medida_fija || '';
        this.producto_medible = listResponse.producto_medible || '';
        this.nombre_unidad = listResponse.nombre_unidad || '';
        
        this.minima_cantidad= listResponse.minima_cantidad || '';
        this.cantidad_inicial= listResponse.cantidad_inicial || '';
        this.incremento= listResponse.incremento || '';
        this.cantidad_prohibida= listResponse.cantidad_prohibida || '';
        
        this.dinamico=listResponse.dinamico||"0";
        this.tiene_disenio=listResponse.tiene_disenio||"0";
        this.es_instalable=listResponse.es_instalable||"0";
        this.ignorar_medida_metrica=listResponse.ignorar_medida_metrica||"0";



    }
}
