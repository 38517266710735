import { GuardarImagenAction, EliminarImagenAction } from "src/core/clientes/action/acciones";
import { Component, OnInit, OnDestroy, AfterViewInit,EventEmitter, Inject, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AppState } from "src/core/state.interface";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'dialog-direccion-component',
    templateUrl: './agregar.dialog.html',
    styleUrls: ['./agregar-dialog.scss']
  })
  export class DireccionDialog implements OnInit, OnDestroy, AfterViewInit {
    public alguito=2;
    public eventE:EventEmitter<any>;
    public data:any={};
    formDireccion:FormGroup;
    constructor(
        private changeDetectRef: ChangeDetectorRef,
        private appState$: Store<AppState>,
      public dialogRef: MatDialogRef<any>,
      @Inject(MAT_DIALOG_DATA) public _data) {
        /* setTimeout(()=>{
          console.log("iniciandos...")
          this.changeDetectRef.detectChanges(); public dialogRef: MatDialogRef<DireccionDialog>,
        },300) */
        this.eventE=_data.eventE;
        this.data=_data;
        let datosViejosdeEntrada=Object.assign(this.data,{});
        if(this.data.eventE)
        this.eventE.subscribe(data=>{
          console.log("resibiendo la data**********")
          const dataAnterior={
            modo:datosViejosdeEntrada.modo,
            editImages:datosViejosdeEntrada.editImages
          };
          this.data=Object.assign(data,dataAnterior);
          this.changeDetectRef.detectChanges();
          console.log("la data",this.data)
        })
  
        
        this.data.modo=0;//0 crear, 1 edicion y borrar, 
        if(this.data.id){
          this.data.modo=1;
          this.alias=this.data.cliente_id;
          this.direccion=this.data.direccion;
        }
        this.formDireccion=new FormGroup({
          alias:new FormControl('',[Validators.required]),
          direccion:new FormControl('',[Validators.required]),
          nombre_recibe:new FormControl('',[]),
          telefono_recibe:new FormControl('',[]),
          observaciones:new FormControl('',[]),
        });
        
      }
  
    subir(){
      document.getElementById("file2").click();
    }
    ngOnDestroy(): void {
     //if(this.eventE)this.eventE.unsubscribe();
    }

    seleccionarImagen(index,event):void{
        this.data.imagenes[index].check=this.data.imagenes[index].check?false:true;
        event.preventDefault();
    }

    eliminarImagen(index,event){
        const params={
            id:this.data.imagenes[index].id,
            cliente_id:this.data.cliente_id
        };
    
        this.appState$.dispatch(new EliminarImagenAction(params))
        event.stopPropagation();
        event.preventDefault();
    }

    
    ngAfterViewInit(): void {
    }
    ngOnInit(): void {
      
    }


    terminar(){
        for(var i=0;i<this.data.imagenes.length;i++){
            if(this.data.imagenes[i].check) return false;
        }
        return true;
    }
  
    alias:string;
    direccion:string;
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    verificarV(validador){
      return this.formDireccion.controls[validador].touched && this.formDireccion.get(validador).invalid;
    }
    getV(campo){
      return this.formDireccion.get(campo);
    }
  
    archivoRef:any;
    public uploadImageDireccion(image){
      this.archivoRef=image.target.files.item(0);
      console.log("subiendo file:",this.archivoRef);
      const params={
        file:this.archivoRef,
        direccion_id:this.data.id,
        cliente_id:this.data.cliente_id
      };
  
      this.appState$.dispatch(new GuardarImagenAction(params))
    }
   
   
  
  }