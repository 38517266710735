/* Devroldan */
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Api } from '../providers/api/api';

@Injectable()
export class ProductControlService extends Api {
  private base: string;
  /* add item to wish list api*/

  public addToWishlist(params: any): Observable<any> {
    this.base = this.getBaseUrl();
    return this.http.post(
      this.base + 'customer/add-product-to-wishlist',
      params
    );
  }

  /* call checkout api*/

  public CheckoutProduct(params: any): Observable<any> {
    this.base = this.getBaseUrl();

    let obs:Observable<any>= this.http.post(this.base + 'orders/customer-checkout', params).pipe(map(res=>{
      console.log("90909090909090909");
      params["promesa"](new Promise((acept,reject)=>{
        if(res){
          acept(res);
        }else{
          reject(res);
        }
      }));
     return res;
    })); 

    //console.log("el estado",params["promesa"]);
    return obs;
    
  }
}
