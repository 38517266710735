import { OK_AGREGAR_ADJUNTO_IMAGEN_DISENIO, ELIMINAR_ADJUNTO_DISENIO, OK_AGREGAR_TEXTO_DISENIO, OK_AGREGAR_LOGO_DISENIO, OK_AGREGAR_REFERENCIA_DISENIO } from "../action/acciones";




export function adjuntosDisenioImagenReducer(state:any[]=[],{type,payload}:any){
    //if(!state)return;
    switch(type){
       /*  case OK_SUBIR_IMAGEN_DISENIO:
            console.log("desde el reducer SUBIR_IMAGEN_DISENIO",payload)
            case OK_SUBIR_IMAGEN_DISENIO:
            console.log("desde el reducer OK_SUBIR_IMAGEN_DISENIO",payload)
            
            return payload.data; */
        case OK_AGREGAR_ADJUNTO_IMAGEN_DISENIO: 
            //console.log("la data!!",payload)
            state.push(payload.data);
        
            return clonarArrayAdjunto(state);
        case ELIMINAR_ADJUNTO_DISENIO:
            state.splice(payload,1);
            return clonarArrayAdjunto(state);
        case OK_AGREGAR_TEXTO_DISENIO:
            //console.log("llego de agregar texto!!")
             state.push(payload.data);
             return clonarArrayAdjunto(state);
        case OK_AGREGAR_LOGO_DISENIO:
            //console.log(stado)
            return eliminarLogoAdjunto(state,payload.data);
        case OK_AGREGAR_REFERENCIA_DISENIO:
                //console.log("llego de agregar texto!!")
                 state.push(payload.data);
                 return clonarArrayAdjunto(state);
        default: {
            return state;
        }
    }


}
function clonarArrayAdjunto(actualArray):any[]{
    if( actualArray.length==0) return;
    var nuevoArray=[];
    for(var i=0;i<actualArray.length;i++){
        nuevoArray.push(Object.assign({},actualArray[i]));
    }
    return nuevoArray; 
}

function eliminarLogoAdjunto(state:any[],payload){
    let arrN=state.filter(pal=>pal.referencia!='logo-disenio-imagen');
    arrN.push(payload);
    return clonarArrayAdjunto(arrN);
}

