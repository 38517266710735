import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ConfigService } from "src/core/service/config.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-agregar-entidad',
    templateUrl: './agregar-entidad.component.html',
    styleUrls: ['./agregar-entidad.compoment.scss']
})
export class AgregarEntidadComponent implements OnInit{
 
    
  @Output() cerrarV = new EventEmitter<boolean>();
  @Output() entidadAgregada = new EventEmitter<any>();
  
  @Input()
  tipo: string;
  @Input()
  hijo: boolean = false;
  @Input()
  padre: any =false;

  

  temporalConctactoValor: any;
  cargandoBusquedaContacto: boolean = false;
  clientes: any[] = [];
  filteredOptions: Observable<ContactoModelo[]>;
  formAutocomplete: FormGroup;
  control_autocompletable: FormControl;

  habilitarCreacion: boolean = false;


  formContacto: FormGroup;

  nombre: string = "";
  apellido_paterno: string = "";
  apellido_materno: string = "";
  email: string = "";
  telefono: string = "";
  extension: string = "";
  celular: string = "";
  calle: string = "";
  num_exterior: string = "";
  num_interior: string = "";
  cp: string = "";
  localidad: string = "";
  municipio: string = "";
  ciudad: string = "";
  estado: string = "";
  pais: string = "";
  public asentamientos: string[] = [];

  formCliente: FormGroup;

  cliente_rfc: string = "";
  cliente_razon_social: string = "";
  cliente_razon_comercial: string = "";
  cliente_marca_perfil: string = "";
  cliente_sector_perfil: string = "";
  cliente_nacimiento2: string = "";
  cliente_telefono: string = "";
  cliente_calle: string = "";
  cliente_num_exterior: string = "";
  cliente_num_interior: string = "";
  cliente_cp: string = "";
  cliente_asentamiento: string = "";
  cliente_municipio: string = "";
  cliente_ciudad: string = "";
  cliente_estado: string = "";
  cliente_pais: string = "";
  public cliente_asentamientos: string[] = [];

  constructor(

    private configService: ConfigService,
    private changeDetectRef: ChangeDetectorRef,
    private http: HttpClient,
  ) {
    window["refActual"] = this;
    this.control_autocompletable = new FormControl();
    this.formAutocomplete = new FormGroup({
      control_autocompletable: this.control_autocompletable
    });

    this.formContacto = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      apellido_paterno: new FormControl('', [Validators.required]),
      apellido_materno: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      telefono: new FormControl(),
      extension: new FormControl(),
      celular: new FormControl(),
      calle: new FormControl(),
      num_exterior: new FormControl(),
      num_interior: new FormControl(),
      cp: new FormControl(),
      localidad: new FormControl(),
      municipio: new FormControl(),
      ciudad: new FormControl(),
      estado: new FormControl(),
      pais: new FormControl(),
    });

    this.formCliente = new FormGroup({
      cliente_rfc: new FormControl('', [Validators.required, this.validarRFC()]),
      cliente_razon_social: new FormControl('', [Validators.required]),
      cliente_razon_comercial: new FormControl('', [Validators.required]),
      cliente_marca_perfil: new FormControl(),
      cliente_sector_perfil: new FormControl(),
      cliente_nacimiento2: new FormControl(),
      cliente_telefono: new FormControl(),
      cliente_calle: new FormControl(),
      cliente_num_exterior: new FormControl(),
      cliente_num_interior: new FormControl(),
      cliente_cp: new FormControl(),
      cliente_asentamiento: new FormControl(),
      cliente_municipio: new FormControl(),
      cliente_ciudad: new FormControl(),
      cliente_estado: new FormControl(),
      cliente_pais: new FormControl(),
    })
  }
  ngOnInit(): void {

    this.filteredOptions = this.control_autocompletable.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
   // this.habilitarCreacion = this.hijo ? false : true;
    //throw new Error("Method not implemented.");
  }



  cerrarVentana() {
    this.cerrarV.emit(false);
  }

  verificarRFC(rfc?) {

    this._verificarRFC(rfc).then(_data => {
      this.changeDetectRef.detectChanges();
    })

  }

  _verificarRFC(rfc) {
    return this.http.get(
      `${this.getBaseUrl()}/customer/verificarRFC/${rfc}`, {

    }

    ).toPromise();
  }

  protected getBaseUrl(): string {
    return environment.storeUrl;
  }


  public error_cp: boolean = false;
  buscarCP(callback?) {
    if (!this["cp"]) return;
    return this.traerDirecciones(this["cp"]).subscribe(data => {
      //data=JSON.parse(data);
      if (!data.error) {
        this.error_cp = false;
        let _data = data["response"];
        this.localidad = _data["asentamiento"][0];
        this.asentamientos = _data["asentamiento"];
        this.municipio = _data["municipio"]
        this.estado = _data["estado"]
        this.ciudad = _data["ciudad"]
        this.pais = _data["pais"]
        this.changeDetectRef.detectChanges();
        //console.log("la data!!",data)
        if (callback)
          callback();
      } else {
        this.error_cp = true;
      }
    });
  }
  //  "proxyConfig": "proxy.config.json"
  buscarCP2(callback?) {
    if (!this["cliente_cp"]) return;
    console.log("bucando")
    this.traerDirecciones(this["cliente_cp"]).subscribe(data => {
      //data=JSON.parse(data);

      if (!data.error) {
        this.error_cp = false;
        let _data = data["response"];
        this.cliente_asentamiento = _data["asentamiento"][0];
        this.cliente_asentamientos = _data["asentamiento"];
        this.cliente_municipio = _data["municipio"]
        this.cliente_estado = _data["estado"]
        this.cliente_ciudad = _data["ciudad"]
        this.cliente_pais = _data["pais"]
        this.changeDetectRef.detectChanges();
        console.log("la data!!", data)
        if (callback)
          callback();

      } else {
        this.error_cp = true;
      }
    });
  }
  traerDirecciones(cp): Observable<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');

    return this.http.get(
      this.getBaseUrl() + `customer/getcp/${cp}`, { headers }
    );
  }

  validarRFC(): ValidatorFn {
    function _rfcValido(rfc, aceptarGenerico = true) {
      const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      if (!rfc) return;
      var validado = rfc.match(re);

      if (!validado)  //Coincide con el formato general del regex?
        return false;

      //Separar el dígito verificador del resto del RFC
      const digitoVerificador = validado.pop(),
        rfcSinDigito = validado.slice(1).join(''),
        len = rfcSinDigito.length,

        //Obtener el digito esperadoentidadAgregada
        diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        indice = len + 1;
      var suma,
        digitoEsperado;

      if (len == 12) suma = 0
      else suma = 481; //Ajuste para persona moral

      for (var i = 0; i < len; i++)
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
      digitoEsperado = 11 - suma % 11;
      if (digitoEsperado == 11) digitoEsperado = 0;
      else if (digitoEsperado == 10) digitoEsperado = "A";

      //El dígito verificador coincide con el esperado?
      // o es un RFC Genérico (ventas a público general)?
      if ((digitoVerificador != digitoEsperado)
        && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
        return false;
      else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
        return false;
      return rfcSinDigito + digitoVerificador;
    }



    return (control: AbstractControl): { [key: string]: any } | null => {
      var rfc = control.value;

      var rfcCorrecto = _rfcValido(rfc);   // ⬅️ Acá se comprueba
      const forbidden = rfcCorrecto ? false : true;
      return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }

  agregarCliente(cliente) {

    return this.http.post(
      this.getBaseUrl() + 'customer/agregar-cliente', cliente

    );
  }
  agregarContacto(contacto) {

    return this.http.post(
      this.getBaseUrl() + 'customer/agregar-contacto', contacto

    );
  }

  unionSeleccionada:any;
  agregarUnir(p) {
    this.unionSeleccionada=p;
    
    let params={
      ...this.unionSeleccionada,
      order_id:this.padre
      
    };
    
    this.http.post(
      this.getBaseUrl() + 'orders/asignar-entidad-orden', params
    ).subscribe(data=>{
      if(data["status"]=="1"){
        this.entidadAgregada.emit(params);
      }
    },e=>{

    });
  }//unir-solicitante-cliente

  desabilitarBotton=false;

  guardarForm() {
    let dataFormulario: any;
    console.log("guardando form");
    this.desabilitarBotton=true;

    if ((this.tipo == "cliente" )) {
      for (let x in this.formCliente["controls"]) {
        if (x) {
          this.formCliente.controls[x].markAsTouched();
          this.formCliente.controls[x].markAsDirty();
        }
      }
      if (this.formCliente.valid) {
        dataFormulario = this.formCliente.getRawValue();
        dataFormulario.padre=this.padre;
        this.agregarCliente(dataFormulario).subscribe(data=>{
          this.desabilitarBotton=false;
          if(data["status"]=="1"){
            dataFormulario.id=data["data"].id;
            this.entidadAgregada.emit({
              tipo:this.tipo,
              hijo:this.padre?true:false,
              datos:{
                tipo:"cliente",
                id:dataFormulario.id,
                rfc:dataFormulario.cliente_rfc,
                razon_comercial:dataFormulario.cliente_razon_comercial,
                razon_social:dataFormulario.cliente_razon_social,

              }
            })
          }

        },err=>{
          this.desabilitarBotton=false;

        });
      }else{
        this.desabilitarBotton=false;
      }
    } else if ((this.tipo == "contacto" )) {
      for (let x in this.formContacto["controls"]) {
        if (x) {
          this.formContacto.controls[x].markAsTouched();
          this.formContacto.controls[x].markAsDirty();
        }
      }
      if (this.formContacto.valid) {
        dataFormulario = this.formContacto.getRawValue();
        dataFormulario.padre=this.padre;
        this.agregarContacto(dataFormulario).subscribe(data=>{
          this.desabilitarBotton=false;
          if(data["status"]=="1"){
            dataFormulario.id=data["data"].id;
            this.entidadAgregada.emit({
              tipo:this.tipo,
              hijo:this.padre?true:false,
              datos:{
                tipo:"contacto",
                id:dataFormulario.id,
                firstName:dataFormulario.nombre,
                lastName:dataFormulario.apellido_paterno+ " " +dataFormulario.apellido_materno,
                username:dataFormulario.email

              }
            })
          }

        },err=>{
          this.desabilitarBotton=false;

        });
      }else{
        this.desabilitarBotton=false;
      }
    }
    

  }



  buscarContactos() {
    var pal = this.temporalConctactoValor;
    this.cargandoBusquedaContacto = true;
    let _tipo=this.tipo=="cliente"?"contacto":"cliente";


    this.http.post(this.getBaseUrl() + "customer/buscar-clientes", { texto_busqueda: pal, tipo: _tipo, c: "sdfklsdj22343423sklfjasfASDFhfjasdf2#$F#423423" }).toPromise()
      .then((data: any) => {

        console.log("la respuesta", data);
        this.options = data.data;
        this.clientes = data.data;

        this.cargandoBusquedaContacto = false;
      })
      .catch(e => {
        this.cargandoBusquedaContacto = false;
      });
  }

  displayFn(_contacto: ContactoModelo): string {
    return _contacto && _contacto.firstName ? _contacto.firstName : '';
  }

  options: ContactoModelo[] = [

  ];

  private _filter(_contacto: string): ContactoModelo[] {
    const filterValue = _contacto.toLowerCase();

    return this.options.filter(option => option.firstName.toLowerCase().includes(filterValue));
  }

  botonCrear() {
    this.habilitarCreacion = true;
  }

}
class ContactoModelo {
  email: string;
  firstName: string;
  grupo_id: string;
  id: string;
  lastName: string;
}