import { Action } from "@ngrx/store";
import { OBTENER_DIRECCIONES, OK_OBTEBNER_DIRECCIONES, AGREGAR_DIRECCION, OK_AGREGAR_DIRECCION, ELIMINAR_DIRECCION } from "../action/acciones";



export function direccionesReducer(state:any={},{type,payload}:any){
    if(!state)return;
    switch(type){
        case OK_OBTEBNER_DIRECCIONES:
            //return Object.assign({},payload.data);
            var capturando=Object.assign({},payload.data)
            console.log("capturando",payload.data)
            return payload.data;
        default: {
            return state;
        }
    }
}

