/* Devroldan */
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ProductControlSandbox } from '../../../../core/product-control/product-control.sandbox';
import { Router } from '@angular/router';
import { ListsSandbox } from '../../../../core/lists/lists.sandbox';

@Component({
  selector: 'app-controls-product-detail',
  templateUrl: './controls-product-detail.component.html',
  styleUrls: ['./controls-product-detail.component.scss']
})
export class ControlsProductDetailComponent implements OnInit {
  // decorator
  @Input() product: any;
  @Input() nombre_unidad: any;
  @Input() type: string;
  @Input() optionNameSelected: any;
  @Input() totalPrice = 0;
  @Output() OpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() QuantityChange: EventEmitter<any> = new EventEmitter<any>();
  // pagination count
  public count = 1;
  public align = 'center center';
  // whislist
  public quantity: any = 1;
  public isWish = [];
  public products: any;

  constructor(
    public snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
    public controlSandbox: ProductControlSandbox,
    public listSandbox: ListsSandbox,
    private router: Router
  ) {}
  cantidad_prohividad:number[]=[];
  // intially get the cart data and calls layoutAlign
  ngOnInit() {

    document["cambiaCantidad"]=(_cantidad,_prod)=>{
      this.quantity=_cantidad;
      _prod.changeDetectRef.detectChanges();
      this.changeQuantity(this.quantity);
    }
    if (this.product) {
      if (this.product.wishListStatus && this.product.wishListStatus === 1) {
        this.isWish[this.product] = 'warn';
      }
      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }
      (this.product.cantidad_prohibida).trim().split(' ').forEach((v,i)=>{
        this.cantidad_prohividad.push(v);
      }); 

      this.changeQuantity((this.product.cantidad_inicial||1));
      this.quantity=parseFloat((this.product.cantidad_inicial||1));
    }
    
    this.layoutAlign();
  }

  // align layout based on condition type
  public layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    } else if (this.type === 'detail') {
      this.align = 'start center';
    } else if (this.type === 'home') {
      this.align = 'start center';
    } else {
      this.align = 'center center';
    }
  }

  // change quantity of the product
  public changeCount(operation) {
    const product: any = {};
    if (operation === 'remove' && this.quantity > (this.product.minima_cantidad||1)) {
      this.quantity -= parseFloat((this.product.incremento||1));
      if(this.quantity<(this.product.minima_cantidad||1)){
        this.quantity=(this.product.minima_cantidad||1);
      }
      if(this.cantidad_prohividad.find(val=>this.quantity==val)>=0){
        console.log('quitando..............')
        return this.changeCount("remove");
      }
    } else if (operation === 'add') {
      if((this.product.minima_cantidad||1)==this.quantity && 
        (this.product.incremento||1)>(this.product.cantidad_inicial||1)){
        this.quantity =parseFloat(this.product.incremento||1);;
      }else{

        
        this.quantity += parseFloat(this.product.incremento||1);;
        if(this.cantidad_prohividad.find(val=>this.quantity==val)>=0){
          console.log('sumando..............')
          return this.changeCount("add");
        }
      }
    }
    if(this.product.incremento<1){
      this.quantity=Math.round(100*this.quantity)/100
    }
   
    this.changeQuantity(this.quantity);
  }

  // add product to cart

  public addToCart(product) {
      this.products = product;
     
      const param: any = {};
      param.totalOptions = this.totalPrice;
      product.productCount = this.quantity;
      product._totalOptions = param.totalOptions;
      this.controlSandbox.addItemsToCart(product, param);
  }
  // emit the data from open product dialoug component
  public openProductDialog(event) {
    this.OpenProductDialog.emit(event);
  }

  // emit quantity while changing
  public changeQuantity(value) {
    this.QuantityChange.emit(value);
  }



  removeTime=0;
  downRemoveT=0;
  upRemoveT=0;
  intervalR:any;

  presionandoRemove(){
    if(this.intervalR) clearInterval(this.intervalR)
    this.downRemoveT=new Date().getTime();
    this.intervalR=setInterval(()=>{
      let tiempoOprimido=(new Date().getTime()-this.downRemoveT)/1000;
      if(tiempoOprimido>1){
        this.changeCount("remove");
      }
    },1);
  }
  
  soltandoRemove(){
    console.log("SOLTANDO REMOVE")
    clearInterval(this.intervalR)
  }




  addTime=0;
  downAddT=0;
  upAddT=0;
  intervalA:any;

  presionandoAdd(){
    if(this.intervalA) clearInterval(this.intervalA)
    this.downAddT=new Date().getTime();
    this.intervalA=setInterval(()=>{
      let tiempoOprimido=(new Date().getTime()-this.downAddT)/1000;
      if(tiempoOprimido>1){
        this.changeCount("add");
      }
    },1);
  }
  soltandoAdd(){
    
    clearInterval(this.intervalA)
  }
}
