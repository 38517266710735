/* Devroldan */
import {Component, HostListener, Input, OnInit, ViewChild, PLATFORM_ID,
    Inject
  } from '@angular/core';
  import { isPlatformBrowser } from '@angular/common';
import {ListsSandbox} from '../../../../core/lists/lists.sandbox';
import {Router} from '@angular/router';
import {AppSettings, Settings} from '../../../app.settings';
import { CommonSandbox } from 'src/core/common/common.sandbox';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    // decorators
    @Input() categories: any;
    @Input() categoriesExpanded: any;
    // param calls getProductList
    private brand: number;
    // local storage
    public clearBrand: string;
    // selecting  category index
    public index: number;
    // category hover
    public hover: any;
    // category id  from event
    public categoryId: string;
    // make category active
    public categorylinkActive: string;
    // theme
    public settings: Settings;

    constructor(public listSandbox: ListsSandbox,
                public appSettings: AppSettings,
                public commonSandbox: CommonSandbox,
                @Inject(PLATFORM_ID) private platformId: Object,
                public router: Router) {
        this.settings = this.appSettings.settings;
        if (isPlatformBrowser(this.platformId)) {
            //devroldan comment
        /* const setTheme = localStorage.getItem('optionsTheme');
        this.settings.theme = setTheme; */
        }
    }

    ngOnInit() {
    }

    /** index for selecting categories.
     * @param index from event
     * @param categoryId from event
     * **/
    indexData(index, id) {
        this.index = index;
        this.categoryId = id;
    }

    // Make category link active if category got selected
    linkActive() {
        this.categorylinkActive = this.categoryId;
    }

    /**
     * calls listSandbox productFilterData and send the value
     * @param productFilter set default value getting from template file
     */
    sendUniqueId(productFilter) {
        this.listSandbox.productFilterData.next(productFilter);
    }


    MenuMap:any[]=[
        {
            nombre:"OFFSET",
            link:"#",
            hijos:[
                /* {
                   nombre:"Vinil Adherible Blanco",
                   link:"/products?keyword=vinil"
                }, 
                {
                    nombre:"Lona",
                    link:"/products/productdetails/623"
                },
                */
                {
                    nombre: "Volantes",
                    link:"/categorias?cat=157"
                } ,
                {
                    nombre: "Trípticos",
                    link:"/categorias?cat="
                } ,
                {
                    nombre: "Dípticos",
                    link:"/categorias?cat="
                } ,
                {
                    nombre: "Postales",
                    link:"/categorias?cat=159"
                } ,
                {
                    nombre: "Colgantes",
                    link:"/categorias?cat="
                } ,
                {
                    nombre: "Folders",
                    link:"/categorias?cat=158"
                } ,
                {
                    nombre: "Formatos",
                    link:"/categorias?cat=165"
                } ,
                {
                    nombre: "Talones",
                    link:"/categorias?cat="
                },
                {
                    nombre: "Calendarios",
                    link:"/categorias?cat="
                } 
                
                

            ]
        },
       
        {
            nombre:"DIGITAL",
            hijos:[
                {
                    nombre: "Volantes",
                    link:"/categorias?cat=157"
                },
                

               
            ]
        },
        { 
            nombre:"GRAN FORMATO",
            link:"/categorias?cat=174"
            /* hijos:[
                {
                    nombre:"VINIL",
                    link:""
                },
                {
                    nombre:"Vinil autoadherible",
                    link:"/products/productdetails/557"
                },
                {
                    nombre:"Electrostático",
                    link:"/products?keyword=electros"
                },
                {
                    nombre:"Microperforado",
                    link:"/products?keyword=perforado"
                },
                {
                    nombre:"Floorgraphic",
                    link:"/products?keyword=gloorgr"
                },
                {
                    nombre:"Tela banner",
                    link:"/products?keyword=banner"
                },
                {
                    nombre:"Coroplast",
                    link:"/products?keyword=coroplast"
                },
                {
                    nombre:"Trovice",
                    link:"/products?keyword=trovice"
                },
                {
                    nombre:"Estireno",
                    link:"/products?keyword=estireno"
                },
                {
                    nombre:"Foamboard",
                    link:"/products?keyword=foamboard"
                },
                {
                    nombre:"Rotulaciones",
                    link:"/products?keyword=rotula"
                },
                {
                    nombre:"Lona front",
                    link:"/products?keyword=lona"
                },
                {
                    nombre:"Lona mesh",
                    link:"/products?keyword=mesh"
                },
                {
                    nombre:"Corte de vinil",
                    link:"/products?keyword=corte"
                },
                {
                    nombre:"Banne",
                    link:"/products?keyword=banne"
                },
                {
                    nombre:"Roll up",
                    link:"/products?keyword=roll"
                },
                {
                    nombre:"Copetes",
                    link:"/products?keyword=copetes"
                },

            ] */
        },
        
    ]
}
