
import {  OK_SUBIR_IMAGEN_DISENIO } from "../action/acciones";




export function imagenDisenioReducer(state:any={},{type,payload}:any){
    if(!state)return;
    switch(type){
            case OK_SUBIR_IMAGEN_DISENIO:
            console.log("desde el reducer OK_SUBIR_IMAGEN_DISENIO",payload)
            
            return payload.data;
        break;
        default: {
            return state;
        }
    }
}

