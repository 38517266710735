import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as store from '../../state.interface';
import { catchError } from 'rxjs/internal/operators';
import * as actions from '../action/acciones'
import {DireccionService} from '../services/direccion.service';
import { AppState } from '../../state.interface';
import { DisenioImagenService } from '../services/disenio-imagen.service';
@Injectable()
export class DireccionEffect {
  constructor(
    private actions$: Actions,
    private dirService:DireccionService,
    private disenioImagenService:DisenioImagenService,
    private appState$: Store<AppState>,
    )
    { 
  }

  @Effect()
  direcciones$: Observable<Action> = this.actions$.pipe(
    ofType(actions.OBTENER_DIRECCIONES),
    map((action: actions.ObtenerDireccionesAction) => action.payload),
    switchMap(state => {
      console.log("buscando direcciones...")
      return this.dirService.obtenerDirecciones(state).pipe(
        map(register => {
          //console.log("obteniendo direcciones!!!!!!!!!!!!",register)
          return new actions.OkDireccionesAction(register)
        }),
        catchError(error => of(new actions.TodoMalDireccionesAction(error)))
      );
    })
  );

  @Effect()
  aniadirDireccion$: Observable<Action> = this.actions$.pipe(
    ofType(actions.AGREGAR_DIRECCION),
    map((action: actions.AgregarDireccionAction) => action.payload),
    switchMap(state => {
      console.log("agregando direccion")
      return this.dirService.crearDireccion(state).pipe(
        map(register => {
          this.appState$.dispatch(new actions.ObtenerDireccionesAction(state.cliente_id));
          return new actions.OkAgregarDireccioneAction(register)}),
        catchError(error => of(new actions.TodoMalAgregarDireccioneAction(error)))
      );
    })
  );

  @Effect()
  eliminarDireccion$: Observable<Action> = this.actions$.pipe(
    ofType(actions.ELIMINAR_DIRECCION),
    map((action: actions.EliminarDireccionAction) => action.payload),
    switchMap(state => {
      console.log("eliminando direccion")
      return this.dirService.eliminarDireccion(state).pipe(
        map(register => {
          console.log("parametros effect ok eliminar",state)
          console.log("register effect ok eliminar",register)
          setTimeout(()=>{

            this.appState$.dispatch(new actions.ObtenerDireccionesAction(state.cliente_id));
          },500)
          return new actions.OKEliminarDireccionAction(register)}),
        catchError(error => of(new actions.TodoMalEliminarDireccionAction(error)))
      );
    })
  );


  @Effect()
  guardarImagenDireccion$: Observable<Action> = this.actions$.pipe(
    ofType(actions.GUARDAR_IMAGEN_DIRECCION),
    map((action: actions.GuardarImagenAction) => action.payload),
    switchMap(state => {
      console.log("guardando direccion")
      return this.dirService.guardarImagenDireccion(state).pipe(
        map(register => {
          
          setTimeout(()=>{
            this.appState$.dispatch(new actions.ObtenerDireccionesAction(state.cliente_id));
          },1000)
          
          return new actions.OKGuardarImagenAction(register)}),
        catchError(error => of(new actions.TODO_MAL_GuardarImagenAction(error)))
      );
    })
  );

  @Effect()
  eliminarImagenDireccion$: Observable<Action> = this.actions$.pipe(
    ofType(actions.ELIMINAR_IMAGEN_DIRECCION),
    map((action: actions.EliminarImagenAction) => action.payload),
    switchMap(state => {
      console.log("eliminando direccion")
      return this.dirService.eliminarImagenDireccion(state).pipe(
        map(register => { 

          setTimeout(()=>{
            this.appState$.dispatch(new actions.ObtenerDireccionesAction(state.cliente_id));
          },1000);
          
          return new actions.OKEliminarImagenAction(register)}),
        catchError(error => of(new actions.TODO_MAL_EliminarImagenAction(error)))
      );
    })
  );



 
  
 
}