import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pPrecios'
})
export class pPrecios implements PipeTransform {
transform(value:any,clase?:string, style?:string):string {
    
    let precio=parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
    
    let entero=precio.split(".")[0];
    let flotante=precio.split(".")[1];

    let container=`
      <div class="precio">
        <span>$${entero}.</span><span>${flotante}</span>
      </div>
      `;
    
    
    //return "<label sdfsdf style='color:red'>${value}2323</label>";
    return container;
  }
}