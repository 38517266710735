import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nombreUnidad'
})
export class nombreUnidad implements PipeTransform {
transform(value:any):string {
    
    let nombre_unidad=value.toString();
    switch(nombre_unidad){
        case "millar": return "millares";
        case "pieza": return "piezas";
    }

    
  }
}