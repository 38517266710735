import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from "@angular/common/http";

import { ConfigService } from '../../service/config.service';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
@Injectable()
export class DireccionService{
   


    constructor(public http: HttpClient, public configService: ConfigService) {}

    protected getBaseUrl(): string {
        return environment.storeUrl;
    }

    obtenerDirecciones(cliente_id):Observable<any>{
        const params=new HttpParams({
            fromObject:{cliente_id}
        });
        return this.http.get(this.getBaseUrl()+"direccion/consultar",{params})
    }
    crearDireccion(data):Observable<any>{
        console.log("enviando data",data)

        const params=new HttpParams({
            fromObject:data
        });
        return this.http.post(this.getBaseUrl()+"direccion/crear",data)
    }

    eliminarDireccion(data: any) {
        const params=new HttpParams({
            fromObject:{id:data.id}
        });
        return this.http.get(this.getBaseUrl()+"direccion/eliminar",{params})
    }

    guardarImagenDireccion(param): Observable<any> {
        const formData = new FormData();
        formData.append('file', param.file);
        formData.append('direccion_id', param.direccion_id);
        formData.append('cliente_id', param.cliente_id);
        console.log("el file:",param.file)
        const options: {
          headers?: HttpHeaders;
          observe?: 'body';
          params?: HttpParams;
          reportProgress?: boolean;
          responseType: 'text';
          withCredentials?: boolean;
        } = {
          headers: new HttpHeaders({ 'xss-loader': 'true' }),
          responseType: 'text' as 'text'
        };
        const headers = new HttpHeaders({ // Angular's HttpHeader
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json', // some google searches suggested i drop this but it still doesnt work
        
        });
        if(!window["pciprint"])window["pciprint"]={};
        const retorno= this.http.post(
          (this.getBaseUrl() + 'media-gcs/upload-imagen-direccion'),
          formData,
          {
            reportProgress: true,
            observe: 'events'
          }
        );
        /* retorno.subscribe(event=>{
          switch (event.type) {
             case HttpEventType.UploadProgress:
              window["pciprint"]["progreso-archivo-subida"]
               = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${window["pciprint"]["progreso-archivo-subida"]}%`);
            break;
          }
        }) */
        return retorno;
      }

      

      editarImagenDireccion(param): Observable<any> {
        const formData = new FormData();
        formData.append('image', param);
        formData.append('path', param.path);
    
        const options: {
          headers?: HttpHeaders;
          observe?: 'body';
          params?: HttpParams;
          reportProgress?: boolean;
          responseType: 'text';
          withCredentials?: boolean;
        } = {
          headers: new HttpHeaders({ 'xss-loader': 'true' }),
          withCredentials: true,
          responseType: 'text' as 'text'
        };
    
        return this.http.post(
          (this.getBaseUrl() + 'media-gcs/upload-imagen-direccion'),
          param
        );
      }

      eliminarImagenDireccion(param):Observable<any>{
        const params=new HttpParams({
            fromObject:param
        })
        return this.http.get(
            (this.getBaseUrl() + 'direccion-imagen/eliminar'),
            {params}
          );
      }
      
}