import { Injectable } from '@angular/core';
import {  Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../../state.interface';
import { DisenioImagenService } from '../services/disenio-imagen.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as actions from '../action/acciones'
import { catchError } from 'rxjs/internal/operators';
import { PARAMETERS } from '@angular/core/src/util/decorators';

@Injectable()
export class DisenioImagenEffect {
  constructor(
    private actions$: Actions,
    private disenioImagenService:DisenioImagenService,
    private appState$: Store<AppState>,
    )
    { 
  }
  
  @Effect()
  subirDisenioImagen$: Observable<Action> = this.actions$.pipe(
    ofType(actions.SUBIR_IMAGEN_DISENIO),
    map((action: actions.SubirImagenDisenioAction) => action.payload),
    switchMap(state => {
      state.ref="prin-disenio-imagen";
      return this.disenioImagenService.guardarDisenioTemp(state).pipe(
        map(register => { 
          //console.log("registrando!!",register);
          return new actions.OkSubirImagenDisenioAction(register)}),
        catchError(error => of(new actions.TODO_MAL_EliminarImagenAction(error)))
      );
    })
  );

  @Effect()
  subirAdjunto$: Observable<Action> = this.actions$.pipe(
    ofType(actions.AGREGAR_ADJUNTO_IMAGEN_DISENIO),
    map((action: actions.AgregarAdjuntoImagenDisenioAction) => action.payload),
    switchMap(state => {
      state.ref="adjunto-disenio-imagen";
      return this.disenioImagenService.guardarDisenioTemp(state).pipe(
        map(register => { 
          //console.log("registrando!!",register);
          return new actions.OkAgregarAdjuntoImagenDisenioAction(register)}),
        catchError(error => of(new actions.TodoMalAgregarAdjuntoImagenDisenioAction(error)))
      );
    })
  );

  @Effect()
  subirlogoadjunto$: Observable<Action> = this.actions$.pipe(
    ofType(actions.AGREGAR_LOGO_DISENIO),
    map((action: actions.AgregarLogoDisenioAction) => action.payload),
    switchMap(state => {
      state.ref="logo-disenio-imagen";
      return this.disenioImagenService.guardarDisenioTemp(state).pipe(
        map(register => { 
          //console.log("registrando!!",register);
          return new actions.OkAgregarLogoDisenioAction(register)}),
        catchError(error => of(new actions.TodoMalAgregarLogoDisenioAction(error)))
      );
    })
  );

  @Effect()
  subirtextoadjunto$: Observable<Action> = this.actions$.pipe(
    ofType(actions.AGREGAR_TEXTO_DISENIO),
    map((action: actions.AgregarTextoDisenioAction) => action.payload),
    switchMap(state => {
      state.ref="texto-disenio-imagen";
      return this.disenioImagenService.guardarDisenioTemp(state).pipe(
        map(register => { 
          //console.log("registrando!!",register);
          return new actions.OkAgregarTextoDisenioAction(register)}),
        catchError(error => of(new actions.TodoMalAgregarTextoDisenioAction(error)))
      );
    })
  );

  @Effect()
  subirrefdisenio$: Observable<Action> = this.actions$.pipe(
    ofType(actions.AGREGAR_REFERENCIA_DISENIO),
    map((action: actions.AgregarReferenciaDisenioAction) => action.payload),
    switchMap(state => {
      state.ref="referencia-disenio-imagen";
      return this.disenioImagenService.guardarDisenioTemp(state).pipe(
        map(register => { 
          //console.log("registrando!!",register);
          return new actions.OkAgregarReferenciaDisenioAction(register)}),
        catchError(error => of(new actions.TodoMalAgregarReferenciaDisenioAction(error)))
      );
    })
  );
  
 
}