import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from "@angular/common/http";

import { ConfigService } from '../../service/config.service';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
@Injectable()
export class DisenioImagenService{
   


    constructor(public http: HttpClient, public configService: ConfigService) {}

    protected getBaseUrl(): string {
        return environment.storeUrl;
    }

   

      guardarDisenioTemp(param): Observable<any> {
        const formData = new FormData();
        formData.append('file', param.file);
        formData.append('ref', param.ref);
        formData.append('cliente_id', param.cliente_id);

        const options: {
          headers?: HttpHeaders;
          observe?: 'body';
          params?: HttpParams;
          reportProgress?: boolean;
          responseType: 'text';
          withCredentials?: boolean;
        } = {
          headers: new HttpHeaders({ 'xss-loader': 'true' }),
          responseType: 'text' as 'text'
        };
        const headers = new HttpHeaders({ // Angular's HttpHeader
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json', // some google searches suggested i drop this but it still doesnt work
        
        });
        if(!window["pciprint"])window["pciprint"]={};
        const retorno= this.http.post(
          (this.getBaseUrl() + 'media-gcs/upload-disenio-producto'),
          formData
        );
        
        return retorno;
      }
      

      editarImagenDireccion(param): Observable<any> {
        const formData = new FormData();
        formData.append('image', param);
        formData.append('path', param.path);
    
        const options: {
          headers?: HttpHeaders;
          observe?: 'body';
          params?: HttpParams;
          reportProgress?: boolean;
          responseType: 'text';
          withCredentials?: boolean;
        } = {
          headers: new HttpHeaders({ 'xss-loader': 'true' }),
          withCredentials: true,
          responseType: 'text' as 'text'
        };
    
        return this.http.post(
          (this.getBaseUrl() + 'media-gcs/upload-imagen-direccion'),
          param
        );
      }

      eliminarImagenDireccion(param):Observable<any>{
        const params=new HttpParams({
            fromObject:param
        })
        return this.http.get(
            (this.getBaseUrl() + 'direccion-imagen/eliminar'),
            {params}
          );
      }
      
}