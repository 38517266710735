import { Action } from "@ngrx/store";

export const OBTENER_DIRECCIONES="[Direcciones obtener direcciones]";
export const OK_OBTEBNER_DIRECCIONES="[Direcciones ok obtener direcciones]";
export const TODO_MAL_OBTEBNER_DIRECCIONES="[Direcciones todo mal obtener direcciones]";


export const AGREGAR_DIRECCION="[Direcciones agregar direcciones]";
export const OK_AGREGAR_DIRECCION="[Direcciones ok direcciones]";
export const TODO_MAL_AGREGAR_DIRECCION="[Direcciones todo mal direcciones]";

export const ELIMINAR_DIRECCION="[Direcciones eliminar direccion]";
export const OK_ELIMINAR_DIRECCION="[Direcciones eliminar ok  direccion]";
export const TODO_MAL_ELIMINAR_DIRECCION="[Direcciones eliminar todo mal direccion]";

export const GUARDAR_IMAGEN_DIRECCION="[Direcciones guardar imagen direccion]";
export const OK_GUARDAR_IMAGEN_DIRECCION="[Direcciones OK guardar imagen direccion]";
export const TODO_MAL_GUARDAR_IMAGEN_DIRECCION="[Direcciones TODO MAL guardar imagen direccion]";

export const EDITAR_IMAGEN_DIRECCION="[Direcciones EDITAR imagen direccion]";
export const OK_EDITAR_IMAGEN_DIRECCION="[Direcciones OK EDITAR imagen direccion]";
export const TODO_MAL_EDITAR_IMAGEN_DIRECCION="[Direcciones TODO MAL EDITAR imagen direccion]";

export const ELIMINAR_IMAGEN_DIRECCION="[Direcciones ELIMINAR imagen direccion]";
export const OK_ELIMINAR_IMAGEN_DIRECCION="[Direcciones OK ELIMINAR imagen direccion]";
export const TODO_MAL_ELIMINAR_IMAGEN_DIRECCION="[Direcciones TODO MAL ELIMINAR imagen direccion]";

export const SUBIR_IMAGEN_DISENIO="[Imagen Diseño SUBIR_IMAGEN_DISENIO]";
export const OK_SUBIR_IMAGEN_DISENIO="[Imagen Diseño OK_SUBIR_IMAGEN_DISENIO]";
export const TODO_MAL_SUBIR_IMAGEN_DISENIO="[Imagen Diseño TODO_MAL_SUBIR_IMAGEN_DISENIO]";

export const AGREGAR_ADJUNTO_IMAGEN_DISENIO="[Imagen Diseño AGREGAR_ADJUNTO_IMAGEN_DISENIO]";
export const OK_AGREGAR_ADJUNTO_IMAGEN_DISENIO="[Imagen Diseño OK_AGREGAR_ADJUNTO_IMAGEN_DISENIO]";
export const TODO_MAL_AGREGAR_ADJUNTO_IMAGEN_DISENIO="[Imagen Diseño TODO_MAL_AGREGAR_ADJUNTO_IMAGEN_DISENIO]";

export const ELIMINAR_ADJUNTO_DISENIO="[Imagen Diseño ELIMINAR_ADJUNTO_DISENIO]";

export const AGREGAR_LOGO_DISENIO="[Imagen Diseño EGREGAR_LOGO ]";
export const OK_AGREGAR_LOGO_DISENIO="[Imagen Diseño OK_EGREGAR_LOGO ]";
export const TODO_MAL_AGREGAR_LOGO_DISENIO="[Imagen Diseño TODO MAL EGREGAR_LOGO ]";

export const AGREGAR_TEXTO_DISENIO="[Imagen Diseño EGREGAR_TEXTO_DISENIO ]";
export const OK_AGREGAR_TEXTO_DISENIO="[Imagen Diseño OK EGREGAR_TEXTO_DISENIO ]";
export const TODO_MAL_AGREGAR_TEXTO_DISENIO="[Imagen Diseño TODO MAL EGREGAR_TEXTO_DISENIO ]";

export const AGREGAR_REFERENCIA_DISENIO="[Imagen Diseño EGREGAR_REFERENCIA_DISENIO ]";
export const OK_AGREGAR_REFERENCIA_DISENIO="[Imagen Diseño OK EGREGAR_REFERENCIA_DISENIO ]";
export const TODO_MAL_AGREGAR_REFERENCIA_DISENIO="[Imagen Diseño TODO MAL EGREGAR_REFERENCIA_DISENIO ]";

//AGREGAR_LOGO_DISENIO

export class AgregarLogoDisenioAction implements Action{
    readonly type=AGREGAR_LOGO_DISENIO;
    constructor(public payload){
        
    }
}
export class OkAgregarLogoDisenioAction implements Action{
    readonly type=OK_AGREGAR_LOGO_DISENIO;
    constructor(public payload){
        
    }
}
export class TodoMalAgregarLogoDisenioAction implements Action{
    readonly type=TODO_MAL_AGREGAR_LOGO_DISENIO;
    constructor(public payload){
        
    }
}

//AGREGAR_TEXTO_DISENIO

export class AgregarTextoDisenioAction implements Action{
    readonly type=AGREGAR_TEXTO_DISENIO;
    constructor(public payload){
        
    }
}
export class  OkAgregarTextoDisenioAction  implements Action{
    readonly type=OK_AGREGAR_TEXTO_DISENIO;
    constructor(public payload){
        
    }
}
export class TodoMalAgregarTextoDisenioAction  implements Action{
    readonly type=TODO_MAL_AGREGAR_TEXTO_DISENIO;
    constructor(public payload){
        
    }
}

//AGREGAR_REFERENCIA_DISENIO

export class AgregarReferenciaDisenioAction implements Action{
    readonly type=AGREGAR_REFERENCIA_DISENIO;
    constructor(public payload){
        
    }
}

export class OkAgregarReferenciaDisenioAction implements Action{
    readonly type=OK_AGREGAR_REFERENCIA_DISENIO;
    constructor(public payload){
        
    }
}

export class TodoMalAgregarReferenciaDisenioAction implements Action{
    readonly type=TODO_MAL_AGREGAR_REFERENCIA_DISENIO;
    constructor(public payload){
        
    }
}


//ELIMINAR ADJUNTO DISEÑO

export class EliminarAdjuntoDisenioAction implements Action{
    readonly type=ELIMINAR_ADJUNTO_DISENIO;
    constructor(public payload){
        
    }
}

/**
 * SUBIR IMAGEN DISENIO
 */
export class AgregarAdjuntoImagenDisenioAction implements Action{
    readonly type=AGREGAR_ADJUNTO_IMAGEN_DISENIO;
    constructor(public payload){
        
    }
}
export class OkAgregarAdjuntoImagenDisenioAction implements Action{
    readonly type=OK_AGREGAR_ADJUNTO_IMAGEN_DISENIO;
    constructor(public payload){
        
    }
}
export class TodoMalAgregarAdjuntoImagenDisenioAction implements Action{
    readonly type=TODO_MAL_AGREGAR_ADJUNTO_IMAGEN_DISENIO;
    constructor(public payload){
        
    }
}

/**
 * SUBIR IMAGEN DISENIO
 */
export class SubirImagenDisenioAction implements Action{
    readonly type=SUBIR_IMAGEN_DISENIO;
    constructor(public payload){
        
    }
}
export class OkSubirImagenDisenioAction implements Action{
    readonly type=OK_SUBIR_IMAGEN_DISENIO;
    constructor(public payload){
        
    }
}

export class TodoMalSubirImagenDisenioAction implements Action{
    readonly type=TODO_MAL_SUBIR_IMAGEN_DISENIO;
    constructor(public payload){
        
    }
    
}

/**
 * OBTENER DIRECCIONES
 */
export class ObtenerDireccionesAction implements Action{
    readonly type=OBTENER_DIRECCIONES;
    constructor(public payload){
        
    }
}
export class OkDireccionesAction implements Action{
    readonly type=OK_OBTEBNER_DIRECCIONES;
    constructor(public payload){
        
    }
}

export class TodoMalDireccionesAction implements Action{
    readonly type=TODO_MAL_OBTEBNER_DIRECCIONES;
    constructor(public payload){
        
    }
    
}


/**
 * AGREGAR DIRECCION
 */

export class AgregarDireccionAction implements Action{
    readonly type=AGREGAR_DIRECCION;
    constructor(public payload){
        
    }
} 
export class OkAgregarDireccioneAction implements Action{
    readonly type=OK_AGREGAR_DIRECCION;
    constructor(public payload){
        
    }
} 
export class TodoMalAgregarDireccioneAction implements Action{
    readonly type=TODO_MAL_AGREGAR_DIRECCION;
    constructor(public payload){
        
    }
} 

/**
 * ELIMINAR DIRECCION
 */
export class EliminarDireccionAction implements Action{
    readonly type=ELIMINAR_DIRECCION;
    constructor(public payload){
        
    }
} 
export class OKEliminarDireccionAction implements Action{
    readonly type=OK_ELIMINAR_DIRECCION;
    constructor(public payload){
        
    }
} 
export class TodoMalEliminarDireccionAction implements Action{
    readonly type=TODO_MAL_ELIMINAR_DIRECCION;
    constructor(public payload){
        
    }
} 

/**
 * GUARDAR IMAGEN REFERENCIA
 */
export class GuardarImagenAction implements Action{
    readonly type=GUARDAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
export class OKGuardarImagenAction implements Action{
    readonly type=OK_GUARDAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
export class TODO_MAL_GuardarImagenAction implements Action{
    readonly type=TODO_MAL_GUARDAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
/**
 * EDITAR IMAGEN REFERENCIA
 */
export class EditarImagenAction implements Action{
    readonly type=EDITAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
export class OKEditarImagenAction implements Action{
    readonly type=OK_EDITAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
export class TODO_MAL_EditarImagenAction implements Action{
    readonly type=TODO_MAL_EDITAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
/**
 * ELIMINAR IMAGEN REFERENCIA
 */
export class EliminarImagenAction implements Action{
    readonly type=ELIMINAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
export class OKEliminarImagenAction implements Action{
    readonly type=OK_ELIMINAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 
export class TODO_MAL_EliminarImagenAction implements Action{
    readonly type=TODO_MAL_ELIMINAR_IMAGEN_DIRECCION;
    constructor(public payload){
        
    }
} 




export type acciones= 
ObtenerDireccionesAction|
OkDireccionesAction|
TodoMalDireccionesAction|
AgregarDireccionAction|
OkAgregarDireccioneAction|
TodoMalAgregarDireccioneAction|
EliminarDireccionAction|
OKEliminarDireccionAction|
TodoMalEliminarDireccionAction|
GuardarImagenAction|
OKGuardarImagenAction|
TODO_MAL_GuardarImagenAction|
EditarImagenAction|
OKEditarImagenAction|
TODO_MAL_EditarImagenAction|
EliminarImagenAction|
OKEliminarImagenAction|
TODO_MAL_EliminarImagenAction|
SubirImagenDisenioAction|
OkSubirImagenDisenioAction|
TodoMalSubirImagenDisenioAction|
AgregarAdjuntoImagenDisenioAction|
OkAgregarAdjuntoImagenDisenioAction|
TodoMalAgregarAdjuntoImagenDisenioAction|
EliminarAdjuntoDisenioAction|
AgregarLogoDisenioAction|
OkAgregarLogoDisenioAction|
TodoMalAgregarLogoDisenioAction|
AgregarTextoDisenioAction|
OkAgregarTextoDisenioAction|
TodoMalAgregarTextoDisenioAction|
AgregarReferenciaDisenioAction|
OkAgregarReferenciaDisenioAction|
TodoMalAgregarReferenciaDisenioAction
;
TodoMalAgregarLogoDisenioAction
 