/* Devroldan */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule
} from '@angular/material';
// component
import { SpinnerComponent } from './spinner/spinner.component';
import { BannersComponent } from './components/banners/banners.component';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { PipesModule } from './pipes/pipes.module';
import { Effect, EffectsModule } from '@ngrx/effects';
import { ProductFilterComponent } from '../pages/products/product-filter/product-filter.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { ProductComponent2 } from './components/producto/product2.component';
import { ProductControlService } from 'src/core/product-control/product-control.service';
import { ProductControlSandbox } from 'src/core/product-control/product-control.sandbox';
import { DireccionService } from 'src/core/clientes/services/direccion.service';
import { DisenioImagenService } from 'src/core/clientes/services/disenio-imagen.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { DireccionEffect } from 'src/core/clientes/effects/direcciones.effect';
import { DisenioImagenEffect } from 'src/core/clientes/effects/disenio-imagen.effect';
import { ConfirmacionDialog } from './components/producto/ventanas-emergentes/confirmacion/confirmacion.component';
import { ProductZoomComponent } from './components/producto/product-zoom/product-zoom.component';
import { CargaDisenioDialog } from './components/producto/ventanas-emergentes/carga-imagen-disenio/carga-disenio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevroldanPipesModule } from '../pipe/pipes.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    DevroldanPipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    PipesModule,
    MatStepperModule,
    ColorPickerModule,
    EffectsModule.forFeature([DireccionEffect,DisenioImagenEffect]),

  ],
  exports: [
    SpinnerComponent,
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    ProductFilterComponent,
    CategoryListComponent,
    ProductComponent2,
    AgregarCliente,
    AgregarEntidadComponent,
    ProductZoomComponent,
    ConfirmacionDialog,
    CargaDisenioDialog,
    ControlsProductDetailComponent,
    BannersComponent  ],
  declarations: [
    AgregarEntidadComponent,
    ProductComponent2,
    AgregarCliente,
    SpinnerComponent,
    CategoryListComponent, 
    BannersComponent,
    ProductFilterComponent,
    ProductZoomComponent,
    ConfirmacionDialog,
    CargaDisenioDialog,
    ControlsProductDetailComponent,
    CategoriaComponent,
    DireccionDialog,
  ],
  entryComponents: [
    AgregarEntidadComponent,
    ProductComponent2,
    AgregarCliente,
    ProductZoomComponent,
    ConfirmacionDialog,
    CargaDisenioDialog,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ProductControlService, ProductControlSandbox,DireccionService,DisenioImagenService
  ]
})
export class SharedModule {}
import { ProductsComponent } from '../pages/products/products.component';import { ControlsProductDetailComponent } from './components/controls-product-detail/controls-product-detail.component';
import { AgregarCliente } from './components/producto/cliente/cliente.component';
import { AgregarEntidadComponent } from '../pages/account/orderdetail/agregar-entidad/agregar-entidad.component';
import { CategoriaComponent } from './components/categoria/categoria.component';
import { DireccionDialog } from './components/producto/ventanas-emergentes/direcciones/dialog-direccion.component';


